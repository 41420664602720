import { Clef } from '../components/views/view.types';
import { Note } from './note';

export type Level = -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5;

export type HalfToneIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export enum NoteName {
  c = 'c',
  cis = 'cis',
  des = 'des',
  d = 'd',
  dis = 'dis',
  es = 'es',
  e = 'e',
  eis = 'eis',
  f = 'f',
  fis = 'fis',
  ges = 'ges',
  g = 'g',
  gis = 'gis',
  as = 'as',
  a = 'a',
  ais = 'ais',
  b = 'b',
  h = 'h'
}

export enum Accidental {
  SHARP = 'sharp',
  DOUBLE_SHARP = 'double-sharp',
  FLAT = 'flat',
  DOUBLE_FLAT = 'double-flat'
}

export enum ScaleType {
  CHROMATIC = 'chromatic',
  MAJOR = 'major',
  MINOR_AEOLIAN = 'minor_aeolian',
  MINOR_HARMONIC = 'minor_harmonic',
  MINOR_MELODIC_UP = 'minor_melodic_up',
  MINOR_MELODIC_DOWN = 'minor_melodic_down',
  DORIAN_MODE = 'dorian_mode',
  PHRYGIAN = 'phrygian_mode',
  LYDIAN = 'lydian_mode',
  MIXOLYDIAN = 'mixolydian_mode',
  LOCRIAN = 'locrian_mode',
}

export type ScaleSchemas = {
  [key in ScaleType]: {
    halfToneIndexes: HalfToneIndex[];
    solmisationNames: {
      base?: { [halfToneIndex: number]: string },
      flat?: { [halfToneIndex: number]: string },
      sharp?: { [halfToneIndex: number]: string }
    }
  }
}

export enum StringType {
  HIGH = 'HIGH',
  MID_HIGH = 'MID_HIGH',
  MID_LOW = 'MID_LOW',
  LOW = 'LOW',
  LOW_5 = 'LOW_5',
  LOW_6 = 'LOW_6'
}

export type StringSchemas = {
  [StringType.HIGH]: HalfToneIndex[];
  [StringType.MID_HIGH]: HalfToneIndex[];
  [StringType.MID_LOW]: HalfToneIndex[];
  [StringType.LOW]: HalfToneIndex[];
  [StringType.LOW_5]?: HalfToneIndex[];
  [StringType.LOW_6]?: HalfToneIndex[];
}

export interface Marker {
  color: string;
  index?: number;
}

export enum Position {
  pos0h = 'pos0h',
  pos1 = 'pos1',
  pos1h = 'pos1h',
  pos2 = 'pos2',
  pos2h = 'pos2h',
  pos3 = 'pos3',
  pos3h = 'pos3h',
  pos4 = 'pos4',
  pos4h = 'pos4h',
  pos5 = 'pos5',
  pos5h = 'pos5h',
  pos6 = 'pos6',
  pos6h = 'pos6h',
  pos7 = 'pos7',
  pos7h = 'pos7h',
  pos8 = 'pos8'
}

export enum Finger {
  thumb = 'thumb',
  f0 = 'f0',
  f1w = 'f1w',
  f1 = 'f1',
  f2 = 'f2',
  f3 = 'f3',
  f4 = 'f4'
}

export type FingerBoardFingeringData = {
  [StringType.HIGH]: FingeringData;
  [StringType.MID_HIGH]: FingeringData;
  [StringType.MID_LOW]: FingeringData;
  [StringType.LOW]: FingeringData;
  [StringType.LOW_5]?: FingeringData;
  [StringType.LOW_6]?: FingeringData;
}

export interface FingeringData {
  [index: number]: FingeringDataForIndex;
}

export interface FingeringDataForIndex {
  finger: Finger;
  note: Note;
  position: Position;
}

export enum IntervalLevel {
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV',
  V = 'V',
  VI = 'VI',
  VII = 'VII'
}

export enum Interval {
  /* eslint-disable @typescript-eslint/naming-convention */
  I = 'I',
  I_aug = 'I_aug',
  II_dim = 'II_dim',
  II_min = 'II_min',
  II_maj = 'II_maj',
  II_aug = 'II_aug',
  III_dim = 'III_dim',
  III_min = 'III_min',
  III_maj = 'III_maj',
  III_aug = 'III_aug',
  IV_dim = 'IV_dim',
  IV = 'IV',
  IV_aug = 'IV_aug',
  V_dim = 'V_dim',
  V = 'V',
  V_aug = 'V_aug',
  VI_dim = 'VI_dim',
  VI_min = 'VI_min',
  VI_maj = 'VI_maj',
  VI_aug = 'VI_aug',
  VII_dim = 'VII_dim',
  VII_min = 'VII_min',
  VII_maj = 'VII_maj'
  /* eslint-enable @typescript-eslint/naming-convention */
}

export enum Degree {
  I = 1,
  II = 2,
  III = 3,
  IV = 4,
  V = 5,
  VI = 6,
  VII = 7
}

export enum Inversion {
  base = 'base',
  inversion1 = 'inversion1',
  inversion2 = 'inversion2'
}

export type PositionsToFingering = {
  [key in Position]?: Fingering
}

export interface Fingering {
  [prop: number]: Finger;
}

export type FingerBoardDefinition = {
  noteAmount: number;
  baseClef?: Clef;
  markers?: Marker[];
  positions?: PositionsToFingering,
  strings: {
    [key in StringType]?: {
      startNote: Note;
    }
  }
}

export interface ScaleIndexedNotesAndMeta {
  [index: number]: {
    note: Note,
    interval: Interval,
    solmisationName: string
  };
}
